import { Button, Checkbox, Collapse, debounce, Grid } from '@material-ui/core';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import Fetching from '../utils/Fetching';
import logoDefault from '../../img/logo.png'
import { AddCircleOutline, RemoveCircleOutline, Room, WhatsApp } from '@material-ui/icons';
import { Calendar } from '@material-ui/pickers';
import moment from 'moment'
import { addAgendamentosNovoCartao, addAgendamentosCartaoSalvo, addAgendamentosPix, getEmpresasByUrl, getHorariosDisponiveis, getDeduction } from './ReservaApi';
import { defaultPrimaryColor, defaultSecondaryColor } from '../../Theme';
import { reservaStyles } from './ReservaStyles'
import { ClienteContext } from '../login/ClienteAuthProvider'
import LoginCliente from '../login/LoginCliente';
import PoliticaCancelamentoModal from './PoliticaCancelamentoModal';
import { Snackbar } from '../../Components/Common';
import RecuperarSenhaCliente from '../login/RecuperarSenhaCliente';
import RecuperarSenhaClienteConfirmacao from '../login/RecuperarSenhaClienteConfirmacao';
import api from '../../services/apiCliente';
import config from '../../config';
import logo from '../../img/logo_h.png';
import currencyFormatter from '../utils/currencyFormatter';
import DadosPix from './DadosPix';
import QRCode from 'react-qr-code';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import DadosCartao from './DadosCartao';
import BigNumber from 'bignumber.js';
import { Alert } from '@material-ui/lab';

const calcValorDesconto = (ag) => {
  const percDesconto = ag.desconto?.perc || 0;
  return new BigNumber(ag.espaco.valorEspaco)
    .times(percDesconto)
    .dividedBy(100)
    .decimalPlaces(2)
    .toNumber()
}

export default (props) => {  
  const [mensagem, setMensagem] = useState();

  const { login, logout, auth } = useContext(ClienteContext);

  const [dadosEmpresa, setDadosEmpresa] = useState({});
  const [horariosDisponiveis, setHorariosDisponiveis] = useState([]);

  const [data, setData] = useState(moment());
  const [horarioSelecionado, setHorarioSelecionado] = useState();
  const [keyHorarioSelecionado, setKeyHorarioSelecionado] = useState();
  const [espacoSelecionado, setEspacoSelecionado] = useState({});
  const [horaExibicao, setHoraExibicao] = useState();

  const [fetchingHorarios, setFetchingHorarios] = useState(false);
  const [fetchingDadosEmpresa, setFetchingDadosEmpresa] = useState(false);
  const [fetchingPagamento, setFetchingPagamento] = useState(false);

  const [isAdding, setIsAdding] = useState(true);

  const [stageLoginReserva, setStageLoginReserva] = useState();
  const [meioPagamento, setMeioPagamento] = useState("pix");
  const [qrCode, setQrCode] = useState();
  const [expirationDate, setExpirationDate] = useState();
  const [pagamentoConfirmado, setPagamentoConfirmado] = useState(false);
  const [erroPagamento, setErroPagamento] = useState();

  const [emailEmRecuperacao, setEmailEmRecuperacao] = useState();

  const [consentimentoCancelamento, setConsentimentoCancelamento] = useState(false);
  const [modalPoliticaCancelamentoOpen, setModalPoliticaCancelamentoOpen] = useState(false);

  const [agendamentosCarrinho, setAgendamentosCarrinho] = useState([]);

  const { logoUrl, endereco, telefone, linkMap } = dadosEmpresa;

  const primaryColor = dadosEmpresa.primaryColor || defaultPrimaryColor;
  const secondaryColor = dadosEmpresa.secondaryColor || defaultSecondaryColor;
  const classes = reservaStyles(primaryColor, secondaryColor);

  const { contatoURL } = config;

  const { match: { params: { id } } } = props;

  const espacosRef = useRef();
  const loginRef = useRef();
  const totalRef = useRef();
  const confirmadoRef = useRef();
  const pagamentoPixRef = useRef();

  useEffect(() => {
    if (horarioSelecionado && espacosRef.current) {
      espacosRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [horarioSelecionado]);

  useEffect(() => {
    if (stageLoginReserva === "login" && loginRef.current) {
      loginRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [stageLoginReserva]);

  useEffect(() => {
    if (totalRef.current) {
      totalRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [agendamentosCarrinho.length]);
  
  useEffect(() => {
    if (pagamentoConfirmado && confirmadoRef.current) {
      confirmadoRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [pagamentoConfirmado]);
  
  useEffect(() => {
    if (pagamentoPixRef.current) {
      pagamentoPixRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [qrCode]);

  useEffect(() => {
    const fetchDadosEmpresa = async() => {

      setFetchingDadosEmpresa(true);

      const response = await getEmpresasByUrl(id);
      if (response.ok && response.data.total > 0) {
        setDadosEmpresa(response.data.data[0]);
      } else {
        setMensagem("Erro ao buscar os horários da academia.");
        setDadosEmpresa({});
      }

      setFetchingDadosEmpresa(false);
    }

    fetchDadosEmpresa();
  }, [id]);

  useEffect(() => {
    const fetchHorariosDisponiveis = async() => {

      setFetchingHorarios(true);

      const response = await getHorariosDisponiveis(data, dadosEmpresa.id);
      if (response.ok) {
        setHorariosDisponiveis(response.data);
      } else {
        setMensagem("Erro ao buscar os horários da academia.");
        setHorariosDisponiveis([]);
      }

      setFetchingHorarios(false);
    }

    if (dadosEmpresa.id) {
      fetchHorariosDisponiveis();
    }  
  }, [dadosEmpresa, data]);

  const [email, setEmail] = useState();
  const unifiedEmail = useMemo(() => auth?.cliente?.user?.email || email, [email, auth])
  
  const [emailQuery, setEmailQuery] = useState();
  const updateEmailDesconto = useCallback(debounce((value) => setEmailQuery(value), 300), []);

  const [deduction, setDeduction] = useState([])

  useEffect(() => {
    updateEmailDesconto(unifiedEmail);
  }, [unifiedEmail, updateEmailDesconto]);

  const shouldUpdateDescontos = useRef(false);

  useEffect(() => {
    const fetchDeduction = async() => {
      const response = await getDeduction(emailQuery, dadosEmpresa.id);
      if (response.ok) {
        setDeduction(response.data);
      } else {
        setDeduction([]);
      }
    }

    if (dadosEmpresa.id && emailQuery) {
      shouldUpdateDescontos.current = true;
      fetchDeduction();
    }
  }, [dadosEmpresa, emailQuery]);

  useEffect(() => {
    if (!shouldUpdateDescontos?.current) {
      return;
    }

    const descontosAplicar = [];
    for (const d of deduction) {
      for (let i = 0; i < d.qtd; i++) {
        descontosAplicar.push(d);
      }
    }

    setAgendamentosCarrinho(agendamentosCarrinho.map((ac, index) => ({
      ...ac,
      desconto: index < descontosAplicar.length ? descontosAplicar[index] : undefined
    })));
    shouldUpdateDescontos.current = false;

  }, [agendamentosCarrinho, deduction])

  const limpaErro = () => {
    setMensagem(undefined);
  }

  const onChangeData = (date) => {
    setData(date);
    setHorarioSelecionado(undefined);
    setKeyHorarioSelecionado(undefined);
    setEspacoSelecionado({});
    setStageLoginReserva(undefined);
  }

  const onClickHorario = (horario) => {
    setHorarioSelecionado(horario.dataHora);
    setKeyHorarioSelecionado(horario.key);
    setHoraExibicao(horario.horaExibicao);
    setEspacoSelecionado({});
    setStageLoginReserva(undefined);
  }

  const onClickEspaco = (espaco) => {
    if (!isHorarioAdicionado(espaco.espacoId, horarioSelecionado)) {
      setEspacoSelecionado(espaco);
      setStageLoginReserva(undefined);
    }
  }

  const handleReservaNovosDados = (values) => {
    setFetchingPagamento(true);
    addAgendamentosNovoCartao(dadosEmpresa.id, agendamentosCarrinho.map(ac => (
      { 
        espacoId: ac.espaco.espacoId,
        dataHora: ac.dataHora,
        nomeEspaco: ac.espaco.nomeEspaco,
        descontoId: ac.desconto?.id,
      }
    )), auth?.cliente?.user?.id, values).then(response => {
      if (response.ok) {
        if (response.data.authentication) {
          login(response.data.authentication);
          api.setToken(response.data.authentication.accessToken)
        }


        setPagamentoConfirmado(true);
        setErroPagamento(undefined);
      } else {
        setErroPagamento(response.data.message);
      }
      setFetchingPagamento(false);
    })
  }

  const handleReservaCartaoSalvo = () => {
    setFetchingPagamento(true);
    addAgendamentosCartaoSalvo(dadosEmpresa.id, agendamentosCarrinho.map(ac => (
      { 
        espacoId: ac.espaco.espacoId,
        dataHora: ac.dataHora,
        nomeEspaco: ac.espaco.nomeEspaco,
        descontoId: ac.desconto?.id,
      }
    )), auth.cliente.user.id).then(response => {
      if (response.ok) {
        setPagamentoConfirmado(true);
        setErroPagamento(undefined);
      } else {
        setErroPagamento(response.data.message);
      }
      setFetchingPagamento(false);
    })
  }

  const handleReservaPix = (values) => {
    setFetchingPagamento(true);
    addAgendamentosPix(dadosEmpresa.id, agendamentosCarrinho.map(ac => (
      { 
        espacoId: ac.espaco.espacoId,
        dataHora: ac.dataHora,
        nomeEspaco: ac.espaco.nomeEspaco,
        descontoId: ac.desconto?.id,
      }
    )), {
      clienteId: auth.logged ? auth.cliente.user.id : undefined,
      ...values
    }).then(response => {
      if (response.ok) {
        if (response.data.authentication) {
          login(response.data.authentication);
          api.setToken(response.data.authentication.accessToken)
        }

        setPagamentoConfirmado(true);
        setQrCode(response.data.qrCode);
        setExpirationDate(response.data.expirationDate);
        setErroPagamento(undefined);
      } else {
        setErroPagamento(response.data.message);
      }
      setFetchingPagamento(false);
    })
  }

  const addAgendamentoCarrinho = () => {
    setAgendamentosCarrinho([
      ...agendamentosCarrinho,
      {
        espaco: espacoSelecionado,
        dataHora: horarioSelecionado,
        key: keyHorarioSelecionado,
        horaExibicao
      }
    ]);
    setHorarioSelecionado(undefined);
    setKeyHorarioSelecionado(undefined);
    setHoraExibicao(undefined);
    setEspacoSelecionado({});
    setIsAdding(false);
    shouldUpdateDescontos.current = true;
  }

  const removeAgendamentoCarrinho = (agendamento) => {
    setAgendamentosCarrinho(agendamentosCarrinho.filter(ac => {
      return !(ac.espaco.espacoId === agendamento.espaco.espacoId && ac.dataHora === agendamento.dataHora)
    }));
  }

  const iniciaAdicaoHorario = () => {
    setIsAdding(true);
  }

  const cancelaAdicao = () => {
    setHorarioSelecionado(undefined);
    setKeyHorarioSelecionado(undefined);
    setHoraExibicao(undefined);
    setEspacoSelecionado({});
    setIsAdding(false);
  }
  
  const isHorarioAdicionado = (espacoId, dataHora) => {
    return agendamentosCarrinho.some(ac => ac.espaco.espacoId === espacoId && ac.dataHora === dataHora);
  }

  const ComponentConsentimento = (
    <>
      <Checkbox
        checked={consentimentoCancelamento}
        onChange={(event) => setConsentimentoCancelamento(event.target.checked)}
      />
      <span className={classes.textoCheckbox}>{`Concordo com a `}</span>
      <span className={classes.textoPoticaCancelamento} onClick={() => setModalPoliticaCancelamentoOpen(true)}>
        {`política de cancelamento.`}
      </span>
    </>
  );

  const ComponentDescontoAplicado = agendamentosCarrinho?.[0]?.desconto ? (
    <Alert severity='success'>Desconto aplicado!</Alert>
  ) : null;

  const ComponentConfirmarReserva = pagamentoConfirmado ? null : (
    <Fetching isFetching={fetchingPagamento}>
      <Button
        variant="contained"
        disabled={!consentimentoCancelamento}
        type="submit"
        className={classes.submitButton}
      >
        {"CONFIRMAR RESERVA"}
      </Button>
    </Fetching>);

  const valorReservas = agendamentosCarrinho
    .map(ag => (ag.espaco.valorEspaco - calcValorDesconto(ag)))
    .reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
  
  const taxa = meioPagamento === "pix" ? parseFloat(dadosEmpresa?.taxaPix || 0.0) : parseFloat(dadosEmpresa?.taxaCartao || 0.0);
  const valorTaxa = valorReservas * (taxa / 100);

  const valorTotal = valorReservas + valorTaxa;

  if (dadosEmpresa?.acesso === 'gobeach') {
    return <Grid container spacing={4} className={classes.redirect}>
      <Grid item xs={12}>
        {'O link desta academia mudou!'}
        <br/>
        {'Acesse o link abaixo para fazer sua reserva.'}
      </Grid>
      <Grid item xs={12}>
        <a href={dadosEmpresa?.redirect || ''}>{dadosEmpresa?.redirect}</a>
      </Grid>
    </Grid>
  }

  return <div className={classes.root}>
    <Fetching isFetching={fetchingDadosEmpresa}>
      <Grid container className={classes.center}>
        <img 
          className={classes.logo}
          src={logoUrl ? logoUrl : logoDefault}
          alt="Logo da Academia"
        />
      </Grid>
      <Grid container className={classes.infoContainer}>
        <span className={classes.nomeEmpresa}>{dadosEmpresa.nome}</span>
      </Grid>
      {telefone && <Grid container className={classes.infoContainer}>
        <a 
          href={`https://wa.me/55${telefone.replace(/\D/g,'')}`} 
          className={classes.mapLinkContainer} 
          target="_blank" 
          rel="noopener noreferrer">
            <span className={classes.whatsText}>{`${telefone} - Contato`}</span>
            <WhatsApp className={classes.whatsIcon}/>
        </a>
      </Grid>}
      <Grid container className={classes.infoContainer}>
        {endereco && <span className={classes.infoText}>{endereco}</span>}
        {linkMap && <a 
          href={linkMap} 
          className={classes.mapLinkContainer} 
          target="_blank" 
          rel="noopener noreferrer">
            <Room className={classes.mapIcon}/>
            <span className={classes.mapText}>{"Como chegar"}</span>
        </a>}
      </Grid>

      {(agendamentosCarrinho.length > 0 || !isAdding) && <>
        <Grid container className={classes.centerContainer}>
          <span className={classes.textoSubtitulo}>
            {"Adicionados à reserva:"}
          </span>
        </Grid>
        <Grid container className={classes.center}>
          {agendamentosCarrinho.map(ag => (
            <Grid item key={ag.espaco.espacoId + ag.dataHora} className={classes.agendamentoCarrinho}>
              <span className={classes.agendamentoCarrinhoText}>
                {ag.espaco.nomeEspaco}
              </span>
              <span className={classes.agendamentoCarrinhoDataHora}>
                {`${moment(ag.dataHora).format('DD/MM/YYYY')}, ${ag.horaExibicao}`}
              </span>
              <span className={classes.agendamentoCarrinhoText}>
                {`${currencyFormatter(ag.espaco.valorEspaco)}`}
              </span>
              {ag.desconto ? (
                <span className={classes.descontoCarrinho}>
                  {`- ${currencyFormatter(calcValorDesconto(ag))}`}
                </span>
              ) : null}
              {!pagamentoConfirmado &&
                <Button onClick={() => removeAgendamentoCarrinho(ag)}>
                  <span className={classes.removeText}>
                    {"remover"}
                  </span>
                  <RemoveCircleOutline
                    className={classes.removeIcon}
                  />  
                </Button>
              }
            </Grid>
          ))}
          {!pagamentoConfirmado && <Grid item className={classes.adicioneItemContainer}>
              <span className={classes.adicioneItensText}>
                {"Adicione mais horários"}
              </span>

              <Button onClick={() => iniciaAdicaoHorario()}>
                <span className={classes.addButtonText}>
                  {"adicionar"}
                </span>
              <AddCircleOutline
                className={classes.addIcon}
              />  
              </Button>
            </Grid>}
        </Grid>
      </>}

      {isAdding && <Grid container className={classes.schedulerContainer}>
        <Grid item>
          <Calendar
            date={data}
            onChange={onChangeData}
            disablePast
            allowKeyboardControl={false}
          />
        </Grid>
        <Fetching isFetching={fetchingHorarios}>
          <Grid item className={classes.horariosContainer}>
            <Grid container>
              {horariosDisponiveis.length === 0 && <span className={classes.mensagemSemHorarios}>
                {"Não há mais horários disponíveis neste dia"}
              </span>}
              {horariosDisponiveis.map(h => (
                <Grid 
                  item 
                  key={h.key} 
                  className={keyHorarioSelecionado === h.key ? classes.horarioSelecionado : classes.horario}
                  onClick={() => onClickHorario(h)}
                >
                  {h.horaExibicao}
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Fetching>
      </Grid>}
      {horarioSelecionado && <>
        <Grid container className={classes.centerContainer}>
          <span className={classes.textoSubtitulo}>
            {"Disponíveis neste horário:"}
          </span>
        </Grid>
        <Grid container className={classes.espacoContainer} ref={espacosRef}>
          {horariosDisponiveis
            .find(h => h.key === keyHorarioSelecionado).espacos
            .map(e => (
              <Grid 
                item 
                key={e.espacoId}
                className={
                  isHorarioAdicionado(e.espacoId, horarioSelecionado)
                  ? classes.espacoIndisponivel
                  : espacoSelecionado.espacoId === e.espacoId 
                    ? classes.espacoSelecionado 
                    : classes.espaco}
                onClick={() => onClickEspaco(e)}
              >
                {e.nomeEspaco}
              </Grid>
            ))}
        </Grid>
      </>}
      {espacoSelecionado?.valorEspaco && <>
        <Grid container className={classes.center}>
          <span className={classes.valor}>{`Valor: R$ ${espacoSelecionado.valorEspaco.toFixed(2).replace('.', ',')}`}</span>
        </Grid>
        <Grid container className={classes.centerContainer}>          
          <Button
            variant="contained"
            className={classes.submitButton}
            onClick={addAgendamentoCarrinho}
          >
            {"ADICIONAR HORÁRIO"}
          </Button>
        </Grid>
      </>}

      {(agendamentosCarrinho.length > 0 && isAdding) && <Grid container className={classes.centerContainer}>          
        <Button
          variant="outlined"
          className={classes.backButton}
          onClick={cancelaAdicao}
        >
          {"VOLTAR"}
        </Button>
      </Grid>}

      {(agendamentosCarrinho.length > 0 && !isAdding) && <>
        <Grid container className={classes.valoresContainer} ref={totalRef}>
          {dadosEmpresa.taxaEmpresa && <>
            <span className={classes.textoValorTotal}>
              {`Total: R$ ${valorReservas.toFixed(2).replace('.', ',')}`}
            </span>
          </>}
          {!dadosEmpresa.taxaEmpresa && <>
            <span className={classes.textoValor}>
              {`Reserva: R$ ${valorReservas.toFixed(2).replace('.', ',')}`}
            </span>
            <span className={classes.textoValor}>
              {`Taxa Conveniência: R$ ${valorTaxa.toFixed(2).replace('.', ',')}`}
            </span>
            <span className={classes.textoValorTotal}>
              {`Total: R$ ${valorTotal.toFixed(2).replace('.', ',')}`}
            </span>
          </>}
        </Grid>
        {!pagamentoConfirmado && <>
          <Grid container className={classes.centerContainer}>
            <span className={classes.textoSubtitulo}>
              {"Método de Pagamento:"}
            </span>
          </Grid>
          <Grid container className={classes.centerPaddingBotom}>
            <Grid item 
              className={meioPagamento === "pix" ? classes.meioPagamentoSelecionado : classes.meioPagamento}
              onClick={() => setMeioPagamento("pix")}
            >
              <span className={classes.meioPagamentoText}>{"PIX"}</span>
              {!dadosEmpresa.taxaEmpresa && <span className={classes.taxaConveniencia}>{`Taxa conveniência: ${dadosEmpresa.taxaPix.replace('.', ',')}%`}</span>}
            </Grid>
            <Grid item 
              className={meioPagamento === "cartao" ? classes.meioPagamentoSelecionado : classes.meioPagamento}
              onClick={() => setMeioPagamento("cartao")}
            >
              <span className={classes.meioPagamentoText}>{"CARTÃO DE CRÉDITO"}</span>
              {!dadosEmpresa.taxaEmpresa && <span className={classes.taxaConveniencia}>{`Taxa conveniência: ${dadosEmpresa.taxaCartao.replace('.', ',')}%`}</span>}
            </Grid>
          </Grid>
          {!auth.logged && <>
            {!stageLoginReserva && meioPagamento && <Grid container className={classes.centerContainer}>
              <span className={classes.textoLoginLogout}>
                {"Já possui conta?"}
              </span>
              <span 
                className={classes.linkLoginLogout}
                onClick={() => setStageLoginReserva("login")}
              >
                {"Faça login"}
              </span>
            </Grid>}

            <Collapse in={stageLoginReserva === "login"}>
              <LoginCliente
                setErro={setMensagem}
                onForgetPasswordClick={() => setStageLoginReserva("esqueci_senha")}
                onBackClick={() => setStageLoginReserva(undefined)}
                classes={classes}
                formRef={loginRef}
                onLoginSuccess={() => setStageLoginReserva(undefined)}
              />
            </Collapse>
            <Collapse in={stageLoginReserva === "esqueci_senha"}>
              <RecuperarSenhaCliente
                onForgetPasswordSuccess={(email) => {
                  setEmailEmRecuperacao(email);
                  setStageLoginReserva("esqueci_senha_confirmacao")
                }}
                onBackClick={() => setStageLoginReserva(undefined)}
                setErro={setMensagem}
                classes={classes}
              />
            </Collapse>
            <Collapse in={stageLoginReserva === "esqueci_senha_confirmacao"}>
              <RecuperarSenhaClienteConfirmacao
                onRecoverPasswordSuccess={() => setStageLoginReserva("login")}
                onForgetPasswordClick={() => setStageLoginReserva("esqueci_senha")}
                onBackClick={() => setStageLoginReserva(undefined)}
                email={emailEmRecuperacao}
                setErro={setMensagem}
                classes={classes}
              />
            </Collapse>
          </>}
          {auth?.logged && meioPagamento && <>            
            <Grid item xs={12} className={classes.centerContainer}>
              <span className={classes.textoLoginLogout}>
                {`Autenticado(a) como ${auth.cliente.user.nome}`}
              </span>
              <span className={classes.linkLoginLogout} onClick={logout}>
                {'(Sair)'}
              </span>
            </Grid>
          </>}
          {!stageLoginReserva && <>
            {meioPagamento === "cartao" &&
              <DadosCartao
                classes={classes}
                setErro={setMensagem}
                handleReservaDadosSalvos={handleReservaCartaoSalvo}
                handleReservaNovosDados={handleReservaNovosDados}
                componentConsentimento={ComponentConsentimento}
                componentConfirmarReserva={ComponentConfirmarReserva}
                componentDescontoAplicado={ComponentDescontoAplicado}
                logged={auth?.logged}
                initialValues={auth.logged ? {
                  email: auth?.cliente?.user?.email,
                  ddd: auth?.cliente?.user?.telefone?.substring(0, 4),
                  telefone: auth?.cliente?.user?.telefone?.substring(5),
                } : {}}
                setEmail={setEmail}
              />
            }
            {meioPagamento === "pix" && 
              <DadosPix 
                classes={classes}
                handleSaveReserva={handleReservaPix}
                componentConsentimento={ComponentConsentimento}
                componentConfirmarReserva={ComponentConfirmarReserva}
                componentDescontoAplicado={ComponentDescontoAplicado}
                logged={auth?.logged}
                initialValues={auth.logged ? auth?.cliente?.user : {}}
                setEmail={setEmail}
              />
            }          
          </>}
        </>}
      </>}

      {erroPagamento && <Grid item xs={12} className={classes.mensagemPagamentoContainer}>
        <span className={classes.mensagemErro}>
          {`Ops, aconteceu um erro ao fazer o agendamento: ${erroPagamento}`}
        </span>
      </Grid>}

      {pagamentoConfirmado && meioPagamento === "cartao" && <>          
        <Grid item xs={12} className={classes.mensagemPagamentoContainer}>
          <span className={classes.mensagemPagamento}>
            {"Reserva efetuada com sucesso!"}
          </span>
        </Grid>
        <Grid item xs={12} className={classes.mensagemConfirmacaoContainer}>
          <span className={classes.mensagemConfirmacao}>
            {`Você receberá a confirmação da reserva em seu e-mail.`}
          </span>
        </Grid>
      </>}    
      {pagamentoConfirmado && qrCode && <>
        <Grid item xs={12} className={classes.mensagemPagamentoContainer} ref={pagamentoPixRef}>
          <span className={classes.textoAtencao}>
            {`Pague pelo QR Code abaixo em ${dadosEmpresa.tempoCancelamentoPix} minutos para confirmar sua reserva!`}
          </span>
        </Grid>
        <Grid item xs={12} className={classes.mensagemPagamentoContainer}>
          <span className={classes.mensagemPix}>
            {`Caso o pagamento não seja realizado em ${dadosEmpresa.tempoCancelamentoPix} minutos, a reserva é automaticamente cancelada e a quadra liberada.`}
          </span>
        </Grid>
        <Grid item xs={12} className={classes.mensagemPagamentoContainer}>
          <QRCode value={qrCode} />
        </Grid>
        <Grid item xs={12} className={classes.mensagemPagamentoContainer}>
          <CopyToClipboard text={qrCode} onCopy={() => setMensagem("Código Copiado!")}>
            <Button
              variant="contained"
              className={classes.submitButton}
            >
                {"COPIAR QR CODE"}
            </Button>
          </CopyToClipboard>
        </Grid>
        <Grid item xs={12} className={classes.mensagemPixContainer}>
          <span className={classes.mensagemPix}>
            {"Você receberá a confirmação da reserva em seu e-mail assim que o pagamento for processado."}
          </span>
        </Grid>
        <Grid item xs={12} className={classes.mensagemPixContainer}>
          <span className={classes.mensagemPix}>
            {`O código do QR Code para o Pix expira às ${moment(expirationDate).format('HH:mm')}.`}
          </span>
        </Grid>
      </>}

      <div ref={confirmadoRef}/>

      <PoliticaCancelamentoModal
        modalOpen={modalPoliticaCancelamentoOpen}
        setModalOpen={setModalPoliticaCancelamentoOpen}
        horasCancelamento={dadosEmpresa?.antecedenciaCancelamento}
      />
    </Fetching>
    
    <Grid container className={classes.contatoContainer}>
      <Grid item xs={12} className={classes.center}>
        <a href={window.location.origin} target="_blank" rel="noopener noreferrer">
          <img 
            src={logo}
            alt="Logo Reservei Online"
            className={classes.reserveiLogo}
          />
        </a>
      </Grid>
      <Grid item xs={12} className={classes.center}>
        <a href={contatoURL} target="_blank" rel="noopener noreferrer">
          <span className={classes.contatoText}>{"Quero colocar o Reservei Online no meu negócio"}</span>
        </a>
      </Grid>
    </Grid>
    <Snackbar message={mensagem} onClose={limpaErro} />
  </div>
}