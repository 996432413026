import React from 'react'
import {Login} from '../../Components/Login'
import api from '../../services/api'
import * as R from 'ramda'
import logo from '../../img/logo.png'

export const handleLogin = ({login, setToken=R.empty}) => async ({username: email, password}) => {
  if (!email || !password) return
  const response = await login({
    email,
    password
  })
  if (response.ok) {
    if (!response.data.user) {
      return {ok: false}
    }
    if (!response.data.accessToken) {
      return {ok: false, message: 'Token não encontrado'}
    }
    setToken(response.data.accessToken)
    return {
      ok: true,
      token: response.data.accessToken,
      id: response.data.user.id,
      email: response.data.user.email,
      name: response.data.user.nome,
      url: response.data.user.url,
      primaryColor: response.data.user.primaryColor,
      secondaryColor: response.data.user.secondaryColor,
      timezone: response.data.user.timezone,
    }
  }
  return {ok: false}
}
const LoginForm = ({history, location}) => {

  const params = new URLSearchParams(location.search);

  return (
    <Login
    logo={
      <img
        alt='logo'
        src={logo}
        style={{ width: 280, marginBottom: 20 }} />
    }
      history={history}
      onSubmit={handleLogin(api)}
      requiredLabel='Obrigatório'
      usernameLabel='E-mail'
      submitLabel='Entrar'
      passwordLabel='Senha'
      recoverPasswordLabel='Recuperar Senha'
      redirectPath={params.get('redirectPath')}
      />
  )
}

export default LoginForm
