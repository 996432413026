import { makeStyles } from '@material-ui/core';

export const reservaStyles = (primaryColor, secondaryColor) => makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  redirect: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    fontSize: 14,
    color: primaryColor,
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingTop: theme.spacing(10)
  },
  center: {
    display: 'flex',
    justifyContent: 'center'
  },
  logo: {
    width: 200
  },
  mapIcon: {
    height: 25,
    color: primaryColor
  },
  whatsIcon: {
    height: 18,
    color: primaryColor,
    paddingLeft: 4
  },
  infoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(1)
  },
  infoText: {
    color: primaryColor,
    fontSize: 15
  },
  mapText: {
    fontSize: 11,
    color: primaryColor,
    textDecoration: 'underline'
  },
  whatsText: {
    fontSize: 14,
    color: primaryColor,
    textDecoration: 'underline'
  },
  mapLinkContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    textAlign: 'center'
  },
  nomeEmpresa: {
    color: primaryColor,
    fontSize: 30,
    fontWeight: 'bold'
  },
  schedulerContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(1),
  },
  horariosContainer: {
    width: 280,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  horario: {
    color: primaryColor,
    border: `1px solid ${primaryColor}`,
    borderRadius: 8,
    margin: theme.spacing(1),
    cursor: 'pointer',
    width: 100,
    fontSize: 13,
    fontWeight: 'bold',
    textAlign: 'center',
    padding: theme.spacing(0.5)
  }, 
  horarioSelecionado: {
    color: theme.palette.white,
    border: `1px solid ${primaryColor}`,
    background: primaryColor,
    borderRadius: 8,
    margin: theme.spacing(1),
    width: 100,
    fontSize: 13,
    fontWeight: 'bold',
    textAlign: 'center',
    padding: theme.spacing(0.5)
  },
  centerPaddingBotom: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: theme.spacing(2)
  },
  espacoContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: theme.spacing(2)
  },
  espaco: {
    width: 200,
    color: primaryColor,
    border: `1px solid ${primaryColor}`,
    borderRadius: 8,
    margin: theme.spacing(1),
    cursor: 'pointer',
    fontSize: 12,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  espacoSelecionado: {
    width: 200,
    color: theme.palette.white,
    border: `1px solid ${primaryColor}`,
    background: primaryColor,
    borderRadius: 8,
    margin: theme.spacing(1),
    cursor: 'pointer',
    fontSize: 12,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  espacoIndisponivel: {
    width: 200,
    color: theme.palette.white,
    border: `1px solid ${secondaryColor}`,
    background: secondaryColor,
    borderRadius: 8,
    margin: theme.spacing(1),
    cursor: 'pointer',
    fontSize: 12,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  meioPagamento: {
    width: 200,
    color: primaryColor,
    border: `1px solid ${primaryColor}`,
    borderRadius: 8,
    margin: theme.spacing(1),
    cursor: 'pointer',
    fontSize: 12,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  meioPagamentoSelecionado: {
    width: 200,
    color: theme.palette.white,
    border: `1px solid ${primaryColor}`,
    background: primaryColor,
    borderRadius: 8,
    margin: theme.spacing(1),
    cursor: 'pointer',
    fontSize: 12,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  meioPagamentoText: {
    fontWeight: 'bold',
    fontSize: 12
  },
  taxaConveniencia: {
    fontSize: 11,
    marginTop: theme.spacing(0.5)
  },
  centerContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2)
  },
  valoresContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2)
  },
  valor: {
    color: primaryColor,
    fontSize: 14,
    fontWeight: 'bold'
  },
  submitButton: {
    color: theme.palette.white,
    background: primaryColor,
    '&:hover': {
      backgroundColor: secondaryColor,
    },
    padding: theme.spacing(2),
    fontWeight: 'bold',
    borderRadius: 8,
  },
  cancelButton: {
    color: theme.palette.white,
    background: primaryColor,
    '&:hover': {
      backgroundColor: secondaryColor,
    },
    padding: theme.spacing(1),
    fontWeight: 'bold',
    fontSize: 12,
    borderRadius: 8,
  },
  fieldContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(0.5)
  },
  submitContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2)
  },
  checkBoxContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(1)
  },
  alertContainer: {
    paddingTop: theme.spacing(1)
  },
  spanContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(2)
  },
  formContainer: {
    display: 'flex',
    justifyContent: 'center',
    maxWidth: 300
  },
  field: {
    width: "100%",
  },
  form: {
    display: 'flex',
    justifyContent: 'center',
  },
  mensagemPixContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  mensagemPix: {
    color: primaryColor,
    fontSize: 12,
    maxWidth: 400,
    textAlign: 'center'
  },
  mensagemPagamentoContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1)
  },
  mensagemConfirmacaoContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: theme.spacing(1)
  },
  mensagemErro: {
    color: primaryColor,
    fontSize: 15,
    fontWeight: 'bold',
    textAlign: 'center'
  },
  mensagemPagamento: {
    color: primaryColor,
    fontSize: 20,
    fontWeight: 'bold',
    textAlign: 'center'
  },
  mensagemEspaco: {
    color: primaryColor,
    fontSize: 15,
    textTransform: 'uppercase'
  },
  mensagemConfirmacao: {
    color: primaryColor,
    fontSize: 15,
    textAlign: 'center'
  },
  mensagemDesconto: {
    color: primaryColor,
    fontSize: 12,
    textAlign: 'center'
  },
  textoCheckbox: {
    fontSize: 12,
    color: primaryColor
  },
  textoPoticaCancelamento: {
    fontSize: 12,
    color: primaryColor,
    textDecoration: 'underline',
    cursor: 'pointer',
    marginLeft: 5
  },
  textoLoginLogout: {
    fontSize: 12,
    color: primaryColor
  },
  linkLoginLogout: {
    fontSize: 12,
    color: primaryColor,
    textDecoration: 'underline',
    cursor: 'pointer',
    marginLeft: 5
  },
  mensagemSemHorarios: {
    color: primaryColor,
    fontSize: 12,
    paddingTop: theme.spacing(2),
  },
  loginButton: {
    color: theme.palette.white,
    background: primaryColor,
    '&:hover': {
      backgroundColor: secondaryColor,
    },
    fontWeight: 'bold',
    borderRadius: 8,
  },
  recoverPasswordButton: {
    color: theme.palette.white,
    background: primaryColor,
    '&:hover': {
      backgroundColor: secondaryColor,
    },
    fontWeight: 'bold',
    borderRadius: 8,
    minWidth: 200
  },
  backButton: {
    borderRadius: 8,
    marginRight: theme.spacing(2)
  },
  agendamentoCarrinho: {
    border: `dashed 1px ${primaryColor}`,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    padding: theme.spacing(1),
    width: 200,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  adicioneItemContainer: {
    border: `dashed 1px ${secondaryColor}`,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    padding: theme.spacing(1),
    width: 200,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  agendamentoCarrinhoText: {
    color: primaryColor,
    fontSize: 12,
    textTransform: 'uppercase',
    textAlign: 'center',
    paddingTop: theme.spacing(0.5)
  },
  descontoCarrinho: {
    color: 'red',
    fontSize: 12,
    textTransform: 'uppercase',
    textAlign: 'center',
    paddingTop: theme.spacing(0.5)
  },
  agendamentoCarrinhoDataHora: {
    color: primaryColor,
    fontSize: 12,
    textAlign: 'center',
    fontWeight: 'bold',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(0.5)
  },
  textoSubtitulo: {
    color: primaryColor,
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: ' center'
  },
  textoAtencao: {
    color: 'red',
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: ' center'
  },
  textoValor: {
    color: primaryColor,
    fontSize: 14,
  },
  textoValorTotal: {
    color: primaryColor,
    fontSize: 18,
    fontWeight: 'bold',
    paddingTop: theme.spacing(1)
  },
  adicioneItensText: {
    color: secondaryColor,
    fontSize: 12,
    paddingBottom: theme.spacing(0.5)
  },
  addButtonText: {
    color: secondaryColor,
    fontSize: 10,
  },
  addIcon: {
    height: 15,
    color: secondaryColor
  },
  removeButton: {
    alignItems: 'center'
  },
  removeIcon: {
    height: 15,
    color: theme.palette.red,
  },
  removeText: {
    fontSize: 10,
    color: theme.palette.red,
  },
  spanPrimaryText: {
    fontSize: 12,
    color: primaryColor,
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  contatoContainer: {
    marginTop: theme.spacing(10),
  },
  reserveiLogo: {
    height: 20,
    marginBottom: theme.spacing(1)
  },
  contatoText: {
    color: theme.palette.primary.main,
    fontSize: 10
  },
  produto: {
    padding: theme.spacing(2),
  }
}))();