import React, { useEffect, useState } from 'react';
import { Button, Grid, makeStyles, MenuItem, Modal, Select } from '@material-ui/core';
import { DateTimePicker } from '@material-ui/pickers';
import Fetching from '../utils/Fetching';
import { atualizarOcupacao } from './AgendaApi';

const useStyles = makeStyles(theme => ({
  modal: {
    background: theme.palette.white,
    position: 'absolute',
    margin: 'auto',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    width: '330px',
    height: '250px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    outline: 0,
    borderRadius: 20
  },
  container: {
    width: '100%'
  },
  labelGrid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    fontSize: 11,
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    padding: theme.spacing(2)
  },
  center: {
    display: 'flex',
    alignItems: 'center',
  },
  buttonGrid: {
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing(2),
    paddingTop: theme.spacing(2)
  },
  selectText: {
    textTransform: 'uppercase',
    color: theme.palette.primary.main
  },
  aviso: {
    fontSize: 12,
    color: 'red',
    textAlign: 'center',
    padding: theme.spacing(2)
  },
  field: {
    width: '100%',
    textTransform: 'uppercase',
    color: theme.palette.primary.main
  },  
  button: {
    backgroundColor: 'red',
    '&:hover': {
      backgroundColor: 'red',
    },
    color: theme.palette.white,
    fontSize: 10,
    fontWeight: 'bold'
  },
}));

export default (props) => {
  const classes = useStyles();

  const {
    espacos,
    espacoId,
    dataHora: dataHoraInit, 
    espaco: espacoInit, 
    versionOcupacoes, 
    setVersionOcupacoes, 
    setErro,
    modalOpen,
    setModalOpen,
  } = props;

  const [dataHora, setDataHora] = useState(dataHoraInit);
  const [espaco, setEspaco] = useState(espacoInit);

  useEffect(() => {
    setDataHora(props.dataHora);
    setEspaco(props.espaco);
  }, [props])

  const [fetching, setFetching] = useState(false);

  const handleSaveOcupacao = () => {
    setFetching(true);
    atualizarOcupacao(espacoId, {
      espacoId: espaco, dataHora 
    }).then(response => {
      if (response.ok) {
        setVersionOcupacoes(versionOcupacoes + 1);
      } else {
        setErro("Não foi possível alterar a ocupação. Contate o Administrador");
      }
    })    
  }
  
  return <Modal
    open={modalOpen}
    onClose={() => setModalOpen(false)}
  >
    <div className={classes.modal}>
      <Fetching isFetching={fetching}> 
        <Grid container className={classes.container}>
          <Grid item xs={4} className={classes.labelGrid}>
            <span>{"ESPAÇO"}</span>
          </Grid>
          <Grid item xs={7} className={classes.center}>
            <Select
              value={espaco}
              onChange={(event) => setEspaco(event.target.value)}
              className={classes.field}
            >
              {espacos && espacos.map(q => (
                <MenuItem value={q.id} key={q.id} className={classes.selectText}>
                  {q.nome}
                </MenuItem>))}
            </Select>
          </Grid>
          <Grid item xs={4} className={classes.labelGrid}>
            <span>{"DATA E HORA"}</span>
          </Grid>
          <Grid item xs={7} className={classes.center}>
            <DateTimePicker
              value={dataHora}
              onChange={setDataHora}
              format="DD/MM/YYYY HH:mm"
              minutesStep={30}
              ampm={false}
              autoOk
              views={['date', 'hours', 'minutes']}
              className={classes.field}
            />
          </Grid>
          <Grid xs={12} className={classes.aviso}>
            {"Ateção! Ao confirmar a alteração, o horário reservado pelo cliente será modificado."}
          </Grid>
          <Grid item xs={12} className={classes.buttonGrid}>
            <Button 
              onClick={() => setModalOpen(false)}
              color="secondary"
              variant="outlined"
              size="small"
            >
              {"VOLTAR"}
            </Button>
            <Button
              className={classes.button}
              size="small"
              onClick={handleSaveOcupacao}
            >
              {"ALTERAR"}
            </Button>
          </Grid>
        </Grid>
      </Fetching>
    </div>
  </Modal>
}