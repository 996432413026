import React from 'react';
import { Grid } from '@material-ui/core';
import { Form } from 'react-final-form';

export default (props) => {
  const { 
    classes, 
    componentConfirmarReserva, 
    componentConsentimento,
    componentDescontoAplicado,
    handleSaveReserva,
    dadosPagamento,
    formRef
  } = props;

  return <Form
    onSubmit={handleSaveReserva}
    render={({ handleSubmit}) => (
      <form onSubmit={handleSubmit} className={classes.form}>
        <Grid container className={classes.form} ref={formRef}> 
          <Grid item xs={12} className={classes.mensagemConfirmacaoContainer}>
            <span className={classes.mensagemEspaco}>
              {dadosPagamento.holder}
            </span>
          </Grid>
          <Grid item xs={12} className={classes.mensagemConfirmacaoContainer}>
            <span className={classes.mensagemEspaco}>
              {`**** ${dadosPagamento.lastDigits}`}
            </span>
          </Grid>
          {componentDescontoAplicado && <Grid item xs={12} className={classes.mensagemDesconto}>
            {'Houve um desconto aplicado para você!'}
          </Grid>}
          <Grid item xs={12} className={classes.infoContainer}>
            {componentConsentimento}
          </Grid>
          <Grid item xs={12} className={classes.submitContainer}>
            {componentConfirmarReserva}
          </Grid>
        </Grid>
      </form>)}/>
}