import { clienteRestApi } from "../../services/apiCliente";

export const getEmpresasByUrl = async (url) => {
  return clienteRestApi.get(`empresas/?url=${url}`);
}

export const getHorariosDisponiveis = async (data, empresaId) => {
  return clienteRestApi.get(`horarios-disponiveis/?data=${data}&empresaId=${empresaId}`);
}

export const getDeduction = async (email, empresaId) => {
  return clienteRestApi.get(`deduction/?email=${email}&empresaId=${empresaId}`);
}

export const buscaCep = async (cep) => {
  return clienteRestApi.get(`busca-cep/${cep}`);
}

export const addAgendamentosCartaoSalvo = async (empresaId, agendamentos, clienteId) => {
  return clienteRestApi.post("/agendamentos", {
    empresaId,
    agendamentos,
    clienteId
  });
}

export const addAgendamentosPix = async (empresaId, agendamentos, values) => {
  const { email, ...rest } = values;
  return clienteRestApi.post("/agendamentos-pix", {
    empresaId,
    agendamentos,
    email: email?.toLowerCase()?.trim(),
    ...rest
  });
}

export const addAgendamentosNovoCartao = async (empresaId, agendamentos, clienteId, values) => {
  return clienteRestApi.post("/agendamentos", {
    empresaId,
    agendamentos,
    clienteId,
    email: values.email.toLowerCase().trim(),
    password: values.password,
    dadosPagamento: {
      card: {
        card_number: values.numero_cartao.replace(/\D/g,''),
        card_expiration_date: values.data_expiracao.replace(/\D/g,''),
        card_holder_name: values.nome_cartao.trim(),
        card_cvv: values.cvv.replace(/\D/g,'')
      },
      customer: {
        name: values.nome_cartao.trim(),
        email: values.email.trim(),
        document_number: values.documento.replace(/\D/g,''),
        zipcode: values.cep.replace(/\D/g,''),
        street: values.logradouro.trim(),
        neighborhood: values.bairro.trim(),
        street_number: values.numero.trim(),
        complementary: values.complemento?.trim(),
        city: values.cidade.trim(),
        state: values.estado.trim(),
        ddd: values.ddd.replace(/\D/g,''),
        number: values.telefone.replace(/\D/g,'')
      }
    }
  });
}

export const cancelaReserva = async (data) => {
  return clienteRestApi.post('cancelar-codigo', data);
}

export const getDadosCliente = async () => {
  return clienteRestApi.get('dados-pagamento');
}

export const getProdutos = async () => {
  return clienteRestApi.get('produtos');
}