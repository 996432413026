import React from 'react';
import { Button, Grid, makeStyles, Modal } from '@material-ui/core';
import Fetching from '../utils/Fetching';

const useStyles = makeStyles(theme => ({
  modal: {
    background: theme.palette.white,
    position: 'absolute',
    margin: 'auto',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    width: '250px',
    height: '150px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    outline: 0,
    borderRadius: 20
  },
  container: {
    width: '100%'
  },
  question: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: 12,
    color: theme.palette.primary.main,
    padding: theme.spacing(2),
    textTransform: 'uppercase'
  },
  buttonGrid: {
    display: 'flex',
    justifyContent: 'space-around',
    paddingTop: theme.spacing(2)
  },
  button: {
    background: theme.palette.red,
    color: theme.palette.white,
    '&:hover': {
      background: theme.palette.strongRed,
    },
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2)
  }
}));

export default (props) => {

  const classes = useStyles();

  const { descontoSelecionado, handleDelete, modalOpen, setModalOpen } = props;
  
  return <Modal
    open={modalOpen}
    onClose={() => setModalOpen(false)}
  >
    <div className={classes.modal}>
      <Fetching isFetching={!descontoSelecionado.email}>
        <Grid container className={classes.container}>
          <Grid item xs={12} className={classes.question}>
            <span>{'TEM CERTEZA QUE DESEJA EXCLUIR O DESCONTO PARA "'}
              <strong>{descontoSelecionado.email}</strong>
            {'"?'}</span>
          </Grid>
          
          <Grid item xs={12} className={classes.buttonGrid}>
            <Button
              className={classes.button}
              size="small"
              onClick={handleDelete}
            >
              {"EXCLUIR"}
            </Button>
            <Button
              color="secondary"
              size="small"
              onClick={() => setModalOpen(false)}
            >
              {"CANCELAR"}
            </Button>
          </Grid>
        </Grid>
      </Fetching>
    </div>
  </Modal>
}