import React, { useState } from 'react';
import { Button, Grid, makeStyles, Modal } from '@material-ui/core';
import moment from 'moment'
import Fetching from '../utils/Fetching';
import { deleteOcupacaoEspaco, cancelarAgendamento, marcarPago, desmarcarPago, deleteHorarioSemanal, deleteDiaOcupado } from './AgendaApi';
import { Snackbar } from '../../Components/Common';
import CopyToClipboard from 'react-copy-to-clipboard';
import EditarOcupacaoModal from './EditarOcupacaoModal';

const useStyles = makeStyles(theme => ({
  modal: {
    background: theme.palette.white,
    position: 'absolute',
    margin: 'auto',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    width: '230px',
    height: '220px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    outline: 0,
    borderRadius: 20,
    padding: theme.spacing(2)
  },
  item: {
    display: 'flex',
    justifyContent: 'center',
  },
  detailRow: {
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.primary.main,
    fontSize: 12,
    fontWeight: 'bold',
    paddingBottom: theme.spacing(1)
  },
  titleRow: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    color: theme.palette.primary.main,
    fontSize: 12,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2)
  },
  buttonRow: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: theme.spacing(1)
  },
  firstButtonRow: {
    display: 'flex',
    justifyContent: 'center'
  },
  textGrid: {
    paddingBottom: theme.spacing(2)
  },
  removeButton: {
    color: theme.palette.red,
    fontSize: 10,
  },
  textCancelar: {
    color: theme.palette.primary.main,
    fontSize: 10
  },
  pagamentoButton: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
    color: theme.palette.white,
    fontSize: 10,
    fontWeight: 'bold'
  },
}));


export default (props) => {
  
  const classes = useStyles();

  const [fetching, setFetching] = useState(false);
  const [cancelamentoSolicitado, setCancelamentoSolicitado] = useState(false);
  const [message, setMessage] = useState();

  const [editarModalOpen, setEditarModalOpen] = useState(false);

  const { 
    modalOpen, 
    setModalOpen, 
    data, 
    setErro, 
    versionOcupacoes, 
    versionHorariosSemanais,
    versionDiasOcupados,
    setVersionOcupacoes,
    setVersionHorariosSemanais,
    setVersionDiasOcupados
  } = props;

  const handleDeleteOcupacao = () => {
    setFetching(true);
    deleteOcupacaoEspaco(data.id).then(response => {
      if (response.ok) {
        setVersionOcupacoes(versionOcupacoes + 1);
      } else {
        setErro("Não foi possível excluir a ocupação. Contate o Administrador.");
      }
    })
  }

  const handleDeleteHorarioSemanal = () => {
    setFetching(true);
    deleteHorarioSemanal(data.id).then(response => {
      if (response.ok) {
        setVersionHorariosSemanais(versionHorariosSemanais + 1);
      } else {
        setErro("Não foi possível excluir o horário. Contate o Administrador.");
      }
    }) 
  }

  const handleDeleteDiaOcupado = () => {
    setFetching(true);
    deleteDiaOcupado(data.id).then(response => {
      if (response.ok) {
        setVersionDiasOcupados(versionDiasOcupados + 1);
      } else {
        setErro("Não foi possível excluir o dia ocupado Contate o Administrador.");
      }
    }) 
  }

  const handleMarcarPago = () => {
    setFetching(true);
    marcarPago(data.id).then(response => {
      if (response.ok) {
        setVersionOcupacoes(versionOcupacoes + 1);
      } else {
        setErro("Não foi possível marcar como pago. Contate o Administrador.");
      }
    })    
  }

  const handleDesmarcarPago = () => {
    setFetching(true);
    desmarcarPago(data.id).then(response => {
      if (response.ok) {
        setVersionOcupacoes(versionOcupacoes + 1);
      } else {
        setErro("Não foi possível desmarcar o pagamento. Contate o Administrador.");
      }
    })    
  }

  const handleCancelamentoAgendamento = () => {
    setFetching(true);
    cancelarAgendamento(data.id).then(response => {
      if (response.ok) {
        setVersionOcupacoes(versionOcupacoes + 1);
      } else {
        setErro("Não foi possível efetuar o cancelamento. Contate o Administrador.");
      }
    })
  }

  const handleClose = () => {
    setCancelamentoSolicitado(false);
    setModalOpen(false);
  }

  return <>
    <Modal
      open={modalOpen}
      onClose={handleClose}
    >
      <div className={classes.modal}>
        <Fetching isFetching={fetching}>
          <Grid container>
            {data && <Grid item xs={12} className={classes.item}>
              <Grid container>
                <Grid item className={classes.detailRow} xs={12}>{`${moment(data.startDate).format('DD/MM/YYYY')}`}</Grid>
                <Grid item className={classes.detailRow} xs={12}>{`${moment(data.startDate).format('HH:mm')} - ${moment(data.endDate).format('HH:mm')}`}</Grid>
                <Grid item className={classes.titleRow} xs={12}>
                  {data.title}
                  <br/>
                  {data.subtitle}
                </Grid>
                {data.qrCode && <Grid item xs={12} className={classes.buttonRow}>
                  <CopyToClipboard text={data.qrCode} onCopy={() => setMessage("Código Copiado!")}>
                    <Button className={classes.pagamentoButton}>
                      {"COPIAR QR CODE"}
                    </Button>
                  </CopyToClipboard>
                </Grid>}
                {data.tipo === 'RESERVA_MANUAL' && <Grid item xs={12} className={classes.buttonRow} >
                  {data.status === 'ABERTO' && <Button 
                    className={classes.pagamentoButton}
                    onClick={() => handleMarcarPago()}
                  >
                    {"MARCAR COMO PAGO"}
                  </Button>}
                  {data.status === 'PAGO' && <Button 
                    className={classes.pagamentoButton}
                    onClick={() => handleDesmarcarPago()}
                  >
                    {"DESMARCAR PAGAMENTO"}
                  </Button>}
                </Grid>}
                
                <Grid item xs={12} className={classes.buttonRow} >
                  <Button 
                    className={classes.removeButton}
                    onClick={() => setEditarModalOpen(true)}
                  >
                    {"ALTERAR RESERVA"}
                  </Button>
                </Grid>

                {['RESERVA_MANUAL', 'INDISPONIVEL'].includes(data.tipo) && <Grid item xs={12} className={classes.buttonRow} >
                  <Button 
                    className={classes.removeButton}
                    onClick={() => handleDeleteOcupacao()}
                  >
                    {"EXCLUIR"}
                  </Button>
                </Grid>}

                {data.tipo === 'HORARIO_SEMANAL' && <Grid item xs={12} className={classes.buttonRow} >
                  <Button 
                    className={classes.removeButton}
                    onClick={() => handleDeleteHorarioSemanal()}
                  >
                    {"EXCLUIR"}
                  </Button>
                </Grid>}

                {data.tipo === 'DIA_OCUPADO' && <Grid item xs={12} className={classes.buttonRow} >
                  <Button 
                    className={classes.removeButton}
                    onClick={() => handleDeleteDiaOcupado()}
                  >
                    {"EXCLUIR BLOQUEIO"}
                  </Button>
                </Grid>}

                {data.tipo === 'AGENDAMENTO' && data.status === 'PAGO' && <>
                  {!cancelamentoSolicitado && <Grid item xs={12} className={classes.buttonRow} >
                    <Button 
                      className={classes.removeButton}
                      onClick={() => setCancelamentoSolicitado(true)}
                    >
                      {"CANCELAR RESERVA"}
                    </Button>
                  </Grid>}
                  {cancelamentoSolicitado && moment().isBefore(moment(data.limiteCancelamento)) && <Grid item xs={12} className={classes.textGrid}>
                    <span className={classes.textCancelar}>
                      {"O cancelamento irá estornar o valor pago e liberar o horário. Deseja prosseguir?"}
                    </span>
                  </Grid>}
                  {cancelamentoSolicitado && moment().isSameOrAfter(moment(data.limiteCancelamento)) && <Grid item xs={12}  className={classes.textGrid}>
                    <span className={classes.textCancelar}>
                      {"O prazo de cancelamento para este estorno já foi ultrapassado. Estornar mesmo assim?"}
                    </span>
                  </Grid>}
                  {cancelamentoSolicitado && <Grid item xs={12} className={classes.buttonRow}>
                    <Button 
                      className={classes.removeButton}
                      onClick={handleCancelamentoAgendamento}
                    >
                      {"CONFIRMAR CANCELAMENTO"}
                    </Button>
                  </Grid>}
                </>}
              </Grid>
            </Grid>}
            <Grid item xs={12} className={classes.item}>
              <Button 
                onClick={handleClose}
                color="secondary"
                variant="outlined"
                size="small"
              >
                {"VOLTAR"}
              </Button>
            </Grid>
          </Grid>
        </Fetching>
      </div>
    </Modal>
    <Snackbar message={message} onClose={() => setMessage(undefined)} />
    <EditarOcupacaoModal
      espacos={props.espacos}
      espacoId={data.id}
      dataHora={moment(data.startDate)}
      espaco={data.espacoId} 
      versionOcupacoes={versionOcupacoes}
      setVersionOcupacoes={setVersionOcupacoes}
      setErro={setErro}
      modalOpen={editarModalOpen}
      setModalOpen={setEditarModalOpen}
    />
  </>
}