import { restApi } from '../../services/api';
import moment from 'moment'

export const fetchAgendas = async (espacoId) => {
  return restApi.get('agendas', {espacoId: espacoId || -1});
}

export const fetchHorariosSemanais = async (espacoId) => {
  return restApi.get('horarios-semanais', {espacoId: espacoId || -1});
}

export const fetchDiasOcupados = async (espacoId) => {
  return restApi.get('excecoes-agenda', {espacoId: espacoId || -1});
}

export const fetchOcupacoesAgenda = async (espacoId) => {
  return restApi.get('ocupacoes-agenda', {
    espacoId: espacoId || -1,
    dataHoraDe: moment().subtract(2, 'month').toDate(),
    dataHoraAte: moment().add(1, 'year').toDate()
  });
}

export const addOcupacaoEspaco = async (tipo, espacoId, dataHora, descricao) => {
  return restApi.post('ocupacoes-agenda', { 
    espacoId, 
    dataHora, 
    descricao,
    tipo,
    status: tipo === "RESERVA_MANUAL" ? "ABERTO" : undefined
  });
}

export const addOcupacaoSemanal = async (espacoId, diaSemana, horario, descricao) => {
  return restApi.post('horarios-semanais', { 
    espacoId, 
    diaSemana,
    horario, 
    descricao,
  });
}

export const deleteOcupacaoEspaco = async (id) => {
  return restApi.delete(`ocupacoes-agenda/${id}`);
}

export const deleteHorarioSemanal = async (id) => {
  return restApi.delete(`horarios-semanais/${id}`);
}

export const deleteDiaOcupado = async (id) => {
  return restApi.delete(`excecoes-agenda/${id}`);
}

export const addExcecaoHorairoSemanal = async (espacoId, dataHora) => {
  return restApi.post('excecao-horario-semanal', { 
    espacoId, 
    dataHora, 
  });
}

export const addDiaOcupado = async (espacoId, dia, descricao) => {
  return restApi.post('excecoes-agenda', { 
    espacoId: espacoId === 'todos' ? undefined : espacoId, 
    dia,
    descricao,
  });
}

export const cancelarAgendamento = async (ocupacaoId) => {
  return restApi.post('cancelamentos', { ocupacaoId });
}

export const marcarPago = async (id) => {
  return restApi.patch(`ocupacoes-agenda/${id}`, {status: "PAGO"});
}

export const desmarcarPago = async (id) => {
  return restApi.patch(`ocupacoes-agenda/${id}`, {status: "ABERTO"});
}

export const atualizarOcupacao = async (id, values) => {
  return restApi.patch(`ocupacoes-agenda/${id}`, values);
}