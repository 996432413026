import React, { useState } from 'react';
import { Button, Grid, makeStyles, Modal } from '@material-ui/core';
import Fetching from '../utils/Fetching';
import TextField from '../../Components/Fields/TextField'
import { Field, Form } from 'react-final-form';
import { emailValido } from '../../utils/utils';

const useStyles = makeStyles(theme => ({
  modal: {
    background: theme.palette.white,
    position: 'absolute',
    margin: 'auto',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    width: '230px',
    height: '280px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    outline: 0,
    borderRadius: 20
  },
  input: {
    display: 'flex',
    justifyContent: 'center',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1)
  },
  button: {
    display: 'flex',
    justifyContent: 'space-around',
    paddingTop: theme.spacing(3)
  },
  field: {
    width: "100%"
  },
}));


export default (props) => {
  
  const classes = useStyles();

  const { modalOpen, setModalOpen, saveAction } = props;

  const [fetching, setFetching] = useState(false);

  const handleSave = async (values) => {
    setFetching(true);
    await saveAction(values);
    setFetching(false);
    setModalOpen(false);
  }

  const validateFields = values => ({
    email: !values.email ? "Campo Obrigatório" : !emailValido(values.email) ? 'Formato inválido' : undefined,
    percentual: !values.percentual ? "Campo Obrigatório" : isNaN(values.percentual) ? 'Formato inválido' : undefined,
    qtdMensal: !values.qtdMensal ? "Campo Obrigatório" : isNaN(values.qtdMensal) ? 'Formato inválido' : undefined
  });

  return <Modal
    open={modalOpen}
    onClose={() => setModalOpen(false)}
  >
    <div className={classes.modal}>
      <Fetching isFetching={fetching}>
        <Form
          onSubmit={handleSave}
          initialValues={{}}
          validate={validateFields}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>   
              <Grid container>
                <Grid item xs={12} className={classes.input}>
                  <Field
                    className={classes.field}
                    name="email"
                    label="E-mail"
                    placeholder="E-mail do cliente"
                    component={TextField}
                  />
                </Grid>
                <Grid item xs={12} className={classes.input}>
                  <Field
                    className={classes.field}
                    name="percentual"
                    label="Percentual de desconto"
                    type="numeric"
                    component={TextField}
                  />
                </Grid>
                <Grid item xs={12} className={classes.input}>
                  <Field
                    className={classes.field}
                    name="qtdMensal"
                    label="Quantidade mensal"
                    type="numeric"
                    component={TextField}
                  />
                </Grid>
                <Grid item xs={12} className={classes.button}>
                  <Button 
                    type="submit"
                    color="primary"
                    variant="contained"
                    size="small"
                  >
                    {"SALVAR"}
                  </Button>
                  <Button
                    color="secondary"
                    size="small"
                    onClick={() => setModalOpen(false)}
                  >
                    {"CANCELAR"}
                  </Button>
                </Grid>
              </Grid>
            </form>)}
          />
      </Fetching>
    </div>
  </Modal>
}