import { restApi } from '../../services/api';

export const getDescontos = async (limit, skip, query) => {
  return restApi.get(`descontos?$limit=${limit}&$skip=${skip}${query ? `&email[$iLike]=%${query}%` : ''}`);
}

export const addDesconto = async (values) => {
  return restApi.post('descontos', values);
}

export const deleteDesconto = async (idDesconto) => {
  return restApi.delete(`descontos/${idDesconto}`);
}

