import React, { useCallback, useEffect, useState } from 'react';
import { Button, debounce, Grid, IconButton, isWidthDown, isWidthUp, makeStyles, Paper, TextField, withWidth } from "@material-ui/core";
import Fetching from '../utils/Fetching';
import Snackbar from '../../Components/Common/Snackbar';
import { addDesconto, deleteDesconto, getDescontos } from './DescontosApi';
import DescontoModal from './DescontoModal';
import { Delete, KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import ExcluirDescontoModal from './ExcluirDescontoModal';

const useStyles = makeStyles(theme => ({
  mainContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(3)
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: theme.spacing(2)
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: 20,
    fontWeight: 'bold'
  },
  buttonGrid: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  textoVazio: {
    fontSize: 12,
    color: theme.palette.primary.main,
  },
  listContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(2)
  },
  listPaper: {
    width: "100%"
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1)
  },
  rowOdd: { 
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.action.hover,
    padding: theme.spacing(1)
  },
  iconButton: {
    padding: 0.5
  },
  item: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    fontSize: 12
  },
  label: {
    fontSize: 12,
    fontWeight: 'bold'
  },
  value: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5)
  },
  arrowsContainer: {
    display: 'flex',
    justifyContent: 'center',
    borderTop: `2px solid ${theme.palette.action.hover}`,
  },
  buscaContainer: {
    paddingBottom: theme.spacing(2)
  },
  buscaInput: {
    width: '100%'
  }
}));

const Descontos = (props) => {
  const { width } = props;

  const classes = useStyles();

  const [version, setVersion] = useState(0);
  const [descontos, setDescontos] = useState();
  const [fetching, setFetching] = useState(false);
  const [mensagem, setMensagem] = useState();
  const [page, setPage] = useState(0);
  const [descontoSelecionado, setDescontoSelecionado] = useState({});
  const [busca, setBusca] = useState('');
  const [query, setQuery] = useState('');

  const pageCount = 20;

  const [adicionarModalOpen, setAdicionarModalOpen] = useState(false);
  const [exclusaoModalOpen, setExclusaoModalOpen] = useState(false);

  const limpaMensagem = () => {
    setMensagem(undefined);
  }

  const updateQuery = useCallback(debounce((value) => setQuery(value), 300), []);

  useEffect(() => {
    updateQuery(busca)
  }, [busca, updateQuery])

  useEffect(() => {
    const fetchDescontos = async() => {

      setFetching(true);

      const response = await getDescontos(pageCount, pageCount * page, query);
      if (response.ok) {
        setDescontos(response.data);
      } else {
        setMensagem("Erro ao buscar o saldo. Contate o Administrador");
        setDescontos({});
      }

      setFetching(false);
    }

    fetchDescontos();
  }, [version, page, query]);

  
  const handleBackButtonClick = (event) => {
    setPage(page - 1);
  };

  const handleNextButtonClick = (event) => {
    setPage(page + 1);
  };

  const handleAddDesconto = (values) => {
    addDesconto(values).then(response => {
      if (response.ok) {
        setVersion(version + 1);
      } else {
        setMensagem("Não foi possível adicionar o desconto");
      }
    });
  }

  const handleDeleteDesconto = () => {
    deleteDesconto(descontoSelecionado.id).then(response => {
      if (response.ok) {
        setVersion(version + 1);
        setDescontoSelecionado({});
        setExclusaoModalOpen(false);
      } else {
        setMensagem("Não foi possível remover o desconto");
      }
    });
  }

  const onClickDelete = (row) => {
    setDescontoSelecionado(row);
    setExclusaoModalOpen(true);
  }

  const ResponsiveValue = ({ label, value }) => <div className={classes.value}>
    {isWidthDown('sm', width) && label && <span className={classes.label}>{label}</span>}
    {value}
  </div>

  return <>
    <Paper>
      <Grid container className={classes.mainContainer}>
        <Grid container className={classes.titleContainer}>
          <span className={classes.title}>{"Descontos Cadastrados"}</span>
        </Grid>
        <Grid item xs={12} className={classes.buttonGrid}>
          <Button 
            color="primary"
            size="small"
            variant="contained"
            onClick={()=> setAdicionarModalOpen(true)}
          >
            {"Adicionar"}
          </Button>
        </Grid>
        
        <Grid item xs={12} className={classes.buscaContainer}>
          <TextField
            className={classes.buscaInput}
            value={busca}
            onChange={(event) => setBusca(event.target.value)}
            placeholder="Digite o e-mail para buscar"
          />
        </Grid>
          
        <Fetching isFetching={fetching}>
          
          <Grid item xs={12} className={classes.listContainer}>
            {!descontos?.length ? (
              <span className={classes.textoVazio}>
                {"Nenhum desconto encontrado. Utilize o botão 'Adicionar' para inserir descontos para clientes."}
              </span>
            ) : (
              <Paper className={classes.listPaper}>
                {isWidthUp('md', width) && <Grid container className={classes.row}>
                  <Grid item md={6} className={classes.item}>
                    <span className={classes.label}>{"E-mail"}</span>
                  </Grid>
                  <Grid item md={2} className={classes.item}>
                    <span className={classes.label}>{"Percentual"}</span>
                  </Grid>
                  <Grid item md={2} className={classes.item}>
                    <span className={classes.label}>{"Qtd Mensal"}</span>
                  </Grid>
                  <Grid item md={2} className={classes.item}>
                    <span className={classes.label}>{"Remover"}</span>
                  </Grid>     
                </Grid>}
                {descontos.map((row, index) => (
                  <Grid container key={row.id} className={index % 2 === 0 ? classes.rowOdd : classes.row}>
                    <Grid item xs={12} sm={12} md={6} className={classes.item}>
                      <ResponsiveValue 
                        label="E-mail: "
                        value={row.email}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2} className={classes.item}>
                      <ResponsiveValue 
                        label="Percentual: "
                        value={`${row.percentual}%`}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2} className={classes.item}>
                      <ResponsiveValue 
                        label="Qtd Mensal: "
                        value={row.qtdMensal}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2} className={classes.item}>
                      <ResponsiveValue 
                        value={<IconButton className={classes.iconButton} onClick={() => onClickDelete(row)}>
                          <Delete />
                        </IconButton>}
                      />
                    </Grid>
                  </Grid>
                ))}
                <Grid container className={classes.arrowsContainer}>           
                  <IconButton onClick={handleBackButtonClick} disabled={page === 0}>
                    <KeyboardArrowLeft />
                  </IconButton>
                  <IconButton
                    onClick={handleNextButtonClick}
                    disabled={descontos.length < pageCount}
                  >
                    <KeyboardArrowRight />
                  </IconButton>
                </Grid>
              </Paper>
            )}
          </Grid>
        </Fetching>
      </Grid>
    </Paper>
    <DescontoModal
      modalOpen={adicionarModalOpen}
      setModalOpen={setAdicionarModalOpen}
      saveAction={handleAddDesconto}
    />
    <ExcluirDescontoModal
      modalOpen={exclusaoModalOpen}
      setModalOpen={setExclusaoModalOpen}
      handleDelete={handleDeleteDesconto}
      descontoSelecionado={descontoSelecionado}
    />
    <Snackbar message={mensagem} onClose={limpaMensagem} />
  </>
}

export default withWidth()(Descontos)